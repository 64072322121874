import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {DEFAULT_LANG, LANGS, LangType, LOCALES} from '@core/types/api/lang.type';

@Injectable({
  providedIn: 'root'
})
export class LangService {

  private lang = new BehaviorSubject<LangType>(DEFAULT_LANG);

  constructor(
    private translateService: TranslateService
  ) {
  }

  initLang(): void {
    const lang = localStorage.getItem('lang') as LangType || DEFAULT_LANG;
    this.updateLang(lang);

    this.translateService.addLangs(LANGS);
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);

    LOCALES.forEach(locale => {
      this.translateService.setTranslation(locale.lang, locale.data, true);
    });
  }

  updateLang(lang: LangType): void {
    this.lang.next(lang);
    this.translateService.use(lang);
    localStorage.setItem('lang', lang);
  }

  get lang$(): Observable<LangType> {
    return this.lang.asObservable();
  }
}
