<div class="wrapper" *ngIf="currLang">

  <div class="langs" [class.collapsed]="collapsed" (click)="onOpenPicker()">

    <div
      class="lang"
      *ngFor="let lang of langs"
      (click)="onUpdateLang(lang.id)"
    >

      <img class="logo" src="./assets/img/{{lang.id}}.svg">

      <span class="name">{{lang.name}}</span>

      <img
        class="arrow-down"
        src="./assets/img/arrow-down.svg"
        [class.hidden]="lang.id !== currLang.id || !collapsed"
      >

    </div>

  </div>

</div>
