import {LocaleModel} from '@core/models/local/locale.model';
import auth from '@assets/i18n/auth/kk';

const localeKk: LocaleModel = {
  lang: 'kk',
  data: {
    auth,
  }
};

export default localeKk;
