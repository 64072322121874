import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {Navigate} from '@ngxs/router-plugin';
import {Moment} from 'moment';
import {Observable} from 'rxjs';

import {TokenModel} from '@core/models/api/token.model';
import {GroupType} from '@core/types/api/group.type';
import {Login, Logout, NavigateHome, RemoveToken, UpdateGroup, UpdateToken} from '@core/states/auth/actions';
import {AuthService} from '@core/services/api/auth.service';
import {getSystemGroups, getTokenExpireDate, isNotNil} from '@core/utils/utils';

export interface AuthStateModel {
  token: TokenModel;
  accessTokenExpireDate?: Moment;
  groups: GroupType[];
  currentGroup: GroupType;
}

const defaults = {
  token: null,
  accessTokenExpireDate: null,
  groups: [],
  currentGroup: null,
};

@State<AuthStateModel>({
  name: 'auth',
  defaults,
})
@Injectable()
export class AuthState {

  @Selector()
  static token(state: AuthStateModel): TokenModel {
    return state.token;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return isNotNil(state.token) && isNotNil(state.accessTokenExpireDate);
  }

  @Selector()
  static accessTokenExpireDate(state: AuthStateModel): Moment {
    return state.accessTokenExpireDate;
  }

  @Selector()
  static currentGroup(state: AuthStateModel): GroupType {
    return state.currentGroup;
  }

  @Selector()
  static systemGroups(state: AuthStateModel): GroupType[] {
    return getSystemGroups(state.groups);
  }

  constructor(
    private store: Store,
    private authService: AuthService,
  ) {
  }

  @Action(Login)
  Login({patchState}: StateContext<AuthStateModel>, {state}: Login): void {
    patchState({...state, accessTokenExpireDate: getTokenExpireDate(state.token.access)});
  }

  @Action(UpdateToken)
  UpdateToken({patchState}: StateContext<AuthStateModel>, {token}: UpdateToken): void {
    patchState({token, accessTokenExpireDate: getTokenExpireDate(token.access)});
  }

  @Action(UpdateGroup)
  UpdateGroup({getState, patchState}: StateContext<AuthStateModel>, {group}: UpdateGroup): void {
    if (getState().currentGroup !== group) {
      patchState({currentGroup: group});
      this.store.dispatch(NavigateHome);
    }
  }

  @Action(RemoveToken)
  RemoveToken({patchState}: StateContext<AuthStateModel>): void {
    patchState(defaults);
  }

  @Action(NavigateHome)
  NavigateHome({getState}: StateContext<AuthStateModel>): Observable<any> {
    const {currentGroup} = getState();
    return this.store.dispatch(new Navigate([currentGroup ? `/${currentGroup}` : '/auth']));
  }

  @Action(Logout)
  Logout({patchState}: StateContext<AuthStateModel>): void {
    this.authService.logout()
      .toPromise()
      .finally(() => {
        patchState(defaults);
        this.store.dispatch(new Navigate(['/auth']));
      });
  }
}
