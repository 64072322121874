import {LangType} from '@core/types/api/lang.type';

export interface LangModel {
  id: LangType;
  name: string;
}

export const LANGS: LangModel[] = [
  {id: 'kk', name: 'Қазақша'},
  {id: 'ru', name: 'Русский'},
];
