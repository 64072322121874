import {ModuleWithProviders, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NgxsModule} from '@ngxs/store';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsRouterPluginModule} from '@ngxs/router-plugin';
import {TranslateModule} from '@ngx-translate/core';
import {ModalModule} from 'ngx-bootstrap/modal';

import {LoaderInterceptor} from '@core/interceptors/loader.intercepter';
import {AuthState} from '@core/states/auth/auth.state';
import {HeaderComponent} from '@core/components/header/header.component';
import {HeaderLangToggleComponent} from '@core/components/header/lang-toggle/header-lang-toggle.component';
import {HeaderGroupToggleComponent} from '@core/components/header/group-toggle/header-group-toggle.component';
import {RequestInterceptor} from '@core/interceptors/request.interceptor';
import {LoaderComponent} from '@core/components/loader/loader.component';
import {ToastComponent} from '@core/components/toast/toast.component';
import {AuthGuard} from '@core/guards/auth.guard';
import {RedirectGuard} from '@core/guards/redirect.guard';
import {NavigatorComponent} from '@core/components/navigator/navigator.component';
import {DialogComponent} from '@core/components/dialog/dialog.component';
import {BreadcrumbsComponent} from '@core/components/breadcrumb/breadcrumbs.component';
import {BreadcrumbsState} from '@core/states/breadcrumbs/breadcrumbs.state';
import {LoaderState} from '@core/states/loader/loader.state';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderLangToggleComponent,
    HeaderGroupToggleComponent,
    NavigatorComponent,
    LoaderComponent,
    ToastComponent,
    DialogComponent,
    BreadcrumbsComponent,
  ],
  exports: [
    HeaderComponent,
    NavigatorComponent,
    LoaderComponent,
    DialogComponent,
    ToastComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    ModalModule.forRoot(),
    NgxsStoragePluginModule.forRoot({key: ['auth']}),
    NgxsModule.forRoot([AuthState, BreadcrumbsState, LoaderState]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    TranslateModule.forRoot(),
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RequestInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LoaderInterceptor,
          multi: true
        },
        AuthGuard,
        RedirectGuard,
      ]
    };
  }
}
