import {AuthStateModel} from '@core/states/auth/auth.state';
import {TokenModel} from '@core/models/api/token.model';
import {GroupType} from '@core/types/api/group.type';

export class Login {
  static readonly type = '[Auth] Login';

  constructor(public state: AuthStateModel) {
  }
}

export class UpdateToken {
  static readonly type = '[Auth] UpdateToken';

  constructor(public token: TokenModel) {
  }
}

export class UpdateGroup {
  static readonly type = '[Auth] UpdateGroup';

  constructor(public group: GroupType) {
  }
}

export class RemoveToken {
  static readonly type = '[Auth] RemoveToken';
}

export class NavigateHome {
  static readonly type = '[Auth] NavigateHome';
}

export class Logout {
  static readonly type = '[Auth] Logout';
}
