import {environment} from '@env';

export class ApiService {

  constructor(
    protected uri: string
  ) {
  }

  protected getUrl(branch?: string | number): string {
    let url = `${environment.jooApiBaseUrlV1}/${this.uri}`;
    if (branch) {
      url += `/${branch}`;
    }
    return `${url}/`;
  }
}
