import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';

import {BreadcrumbsState} from '@core/states/breadcrumbs/breadcrumbs.state';
import {BreadcrumbModel} from '@core/models/local/breadcrumb.model';

@Component({
  selector: 'core-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {

  @Select(BreadcrumbsState.items) breadcrumbs$: Observable<BreadcrumbModel[]>;

  constructor(
    private router: Router,
  ) {
  }

  onClickBreadcrumb(event: MouseEvent, {navigateRoute}: BreadcrumbModel): void {
    if (!navigateRoute) {
      return;
    }

    if (event.ctrlKey || event.metaKey) {
      const url = this.router.createUrlTree([navigateRoute]);
      window.open(url.toString(), '_blank');
    } else {
      this.router.navigateByUrl(navigateRoute);
    }
  }
}
