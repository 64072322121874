import localeKk from '@assets/i18n/kk';
import localeRu from '@assets/i18n/ru';

export type LangType = 'kk' | 'ru';

export const LANGS: LangType[] = ['kk', 'ru'];

export const LOCALES = [localeKk, localeRu];

export const DEFAULT_LANG = 'kk';
