import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

import {ApiService} from '@core/services/api/api.service';
import {LoginModel} from '@core/models/api/login.model';
import {TokenModel} from '@core/models/api/token.model';
import {HTTP_SKIP_AUTH_TOKEN_EXPIRATION_CHECK, HTTP_SKIP_ERROR_HANDLING} from '@core/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends ApiService {

  constructor(
    protected http: HttpClient
  ) {
    super('identity');
  }

  login(payload: LoginModel): Observable<TokenModel> {
    return this.http.post<TokenModel>(this.getUrl('auth/token'), payload);
  }

  refreshToken(refresh: string): Observable<TokenModel> {
    return this.http.post<TokenModel>(this.getUrl('auth/token/refresh'), {refresh}, {
      params: {
        [HTTP_SKIP_AUTH_TOKEN_EXPIRATION_CHECK]: 'true'
      }
    });
  }

  logout(): Observable<any> {
    return this.http.post<any>(this.getUrl('auth/logout'), null, {
      params: {
        [HTTP_SKIP_AUTH_TOKEN_EXPIRATION_CHECK]: 'true',
        [HTTP_SKIP_ERROR_HANDLING]: 'true',
      }
    });
  }

  getProfile(token: string): Observable<any> {
    return this.http.get<any>(this.getUrl('profile'), {
      headers: new HttpHeaders({Authorization: `Bearer ${token}`})
    });
  }
}
