import {LocaleModel} from '@core/models/local/locale.model';
import auth from '@assets/i18n/auth/ru';

const localeRu: LocaleModel = {
  lang: 'ru',
  data: {
    auth,
  }
};

export default localeRu;
