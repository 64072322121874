import {GroupType} from '@core/types/api/group.type';

export const HTTP_SKIP_ERROR_HANDLING = 'skip_error_handling';
export const HTTP_SKIP_AUTH_TOKEN_EXPIRATION_CHECK = 'skip_auth_token_expiration_check';
export const SYSTEM_GROUPS: GroupType[] = ['consult-front', 'consult-cashier', 'consult-middle', 'consult-back'];

export const GRANT_TYPE_GENERAL = 0;
export const GRANT_TYPE_TARGET = 1;
export const GRANT_TYPE_SERPIN = 2;
export const GRANT_TYPE_GENERAL_RURAL = 3;
export const GRANT_TYPE_TARGET_RURAL = 4;
export const GRANT_TYPE_SERPIN_RURAL = 5;
export const GRANT_TYPE_TARGET_ATYRAU = 6;
export const GRANT_TYPE_TARGET_MANGYSTAU = 7

export const GRANT_TYPE_TARGETS = [
  GRANT_TYPE_TARGET,
  GRANT_TYPE_SERPIN,
  GRANT_TYPE_TARGET_RURAL,
  GRANT_TYPE_SERPIN_RURAL,
  GRANT_TYPE_TARGET_ATYRAU,
  GRANT_TYPE_TARGET_MANGYSTAU
];

export const GRANT_TYPE_SERPINS = [GRANT_TYPE_SERPIN, GRANT_TYPE_SERPIN_RURAL];

export const CONSULT_STATE_FRONT = 0;
export const CONSULT_STATE_CASHIER = 1;
export const CONSULT_STATE_MIDDLE = 2;
export const CONSULT_STATE_BACK = 3;
export const CONSULT_STATE_FINISHED = 4;

export const CONSULT_STATE_LABEL_MAP = {
  [CONSULT_STATE_FRONT]: 'Новый',
  [CONSULT_STATE_CASHIER]: 'Ожидание оплаты пользователем',
  [CONSULT_STATE_MIDDLE]: 'Построение выборов',
  [CONSULT_STATE_BACK]: 'Ожидание подтверждения модератором',
  [CONSULT_STATE_FINISHED]: 'Завершен'
};
