import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngxs/store';

import {AuthState} from '@core/states/auth/auth.state';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private store: Store,
    private router: Router,
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.store.selectSnapshot(AuthState.token)) {
      console.log('[AuthGuard] Has token, entering app.');
      return true;
    }

    console.log('[AuthGuard] No token, redirecting auth.');
    this.router.navigate(['/auth']);
    return false;
  }
}
