import {clone, filter, isEmpty as _isEmpty, isNil, prop, sortBy} from 'ramda';
import moment, {Moment} from 'moment';

import {GRANT_TYPE_TARGETS, SYSTEM_GROUPS} from '@core/constants/constants';
import {ConsulteeModel, ExamModel} from '@core/models/api/consultee.model';

export const isNotNil = x => !isNil(x);

export const isEmpty = x => isNil(x) || _isEmpty(x);

export const isNotEmpty = x => isNotNil(x) && !_isEmpty(x);

export const getTokenExpireDate = (token: string): Moment => {
  const encoded = token.split('.')[1];
  const decoded = JSON.parse(window.atob(encoded));
  return moment.unix(decoded.exp);
};

export const getSystemGroups = filter(group => SYSTEM_GROUPS.includes(group));

export const getPhoneWithPrefix = (phone: string) => `+7${phone}`;

export const getPhoneURI = (phone: string) => {
  const encoded = encodeURI(`+7${phone}`);
  return `?phone=${encoded}`.replace('+', '%2B');
};

export const getExamParams = (exam: ExamModel) => {
  const {subject_1_id: subject_1, subject_2_id: subject_2, language} = exam;
  return {subject_1, subject_2, language};
};

export const getConsulteeWithSortedExams = (consultee: ConsulteeModel): ConsulteeModel => {
  return {
    ...consultee,
    results: sortBy(prop('id'), consultee.results)
  };
};

export const getAreasWithPreparedCheckbox = (exam: ExamModel) => (items: any[]): any[] => {
  return items.map(item => ({
    ...item,
    checkboxId: Math.random().toString(),
    selected: !exam.area_preferences || exam.area_preferences.includes(item.id)
  }));
};

export const getRegionsWithPreparedCheckbox = (exam: ExamModel) => (items: any[]): any[] => {
  return items.map(item => ({
    ...item,
    checkboxId: Math.random().toString(),
    selected: !exam.region_preferences || exam.region_preferences.includes(item.id)
  }));
};

export const extractExamChoices = (exam: ExamModel) => {
  for (let i = 1; i <= 4; i++) {
    const groupKey = `group_choice_${i}`;
    const grantKey = `grant_choice_${i}`;
    const universityKey = `university_choice_${i}`;

    if (exam[groupKey]) {
      let choice = null;

      if (GRANT_TYPE_TARGETS.includes(exam[grantKey])) {
        choice = exam.stat.stats.find(stat => {
          return stat.group.id === exam[groupKey] && stat.type === exam[grantKey] && stat.university.id === exam[universityKey]
        });
      } else {
        choice = exam.stat.stats.find(stat => {
          return stat.group.id === exam[groupKey] && stat.type === exam[grantKey]
        });
      }

      if (choice) {
        choice.universityId = exam[universityKey];
        exam.choices.push(clone(choice));
      }
    }
  }
  return exam;
};

export const extractExamForms = (exams: ExamModel[]): ExamModel[] => {
  return exams.map(exam => ({...exam, saved: true, totalDisabled: true}));
};
