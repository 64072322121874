<div class="wrapper" *ngIf="currentGroup">

  <div class="groups" [class.collapsed]="collapsed" (click)="onOpenPicker()">

    <div
      class="group"
      *ngFor="let group of groups"
      (click)="onUpdateGroup(group)"
    >

      <img class="logo" src="./assets/img/{{group}}.svg">

      <span class="name">{{getGroupName(group)}}</span>

      <img
        class="arrow-down"
        src="./assets/img/arrow-down.svg"
        [class.hidden]="group !== currentGroup || !collapsed"
      >

    </div>

  </div>

</div>
