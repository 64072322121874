import {Component, OnDestroy} from '@angular/core';
import {Store} from '@ngxs/store';
import {Subscription} from 'rxjs';

import {AuthState} from '@core/states/auth/auth.state';
import {GroupType} from '@core/types/api/group.type';
import {UpdateGroup} from '@core/states/auth/actions';

const GROUP_LABEL_MAP = {
  'consult-front': 'Фронт',
  'consult-cashier': 'Кассир',
  'consult-middle': 'Миддл',
  'consult-back': 'Бэк',
};

@Component({
  selector: 'core-header-group-toggle',
  templateUrl: './header-group-toggle.component.html',
  styleUrls: ['./header-group-toggle.component.scss'],
})
export class HeaderGroupToggleComponent implements OnDestroy {

  groups: GroupType[];

  currentGroup: GroupType;

  currentGroupSubscription: Subscription;

  collapsed = true;

  constructor(
    private store: Store
  ) {
    this.currentGroupSubscription = this.store.select(AuthState.currentGroup)
      .subscribe(currentGroup => {
        const systemGroups = this.store.selectSnapshot(AuthState.systemGroups);
        if (systemGroups.length > 1) {
          this.currentGroup = currentGroup;
          this.groups = [currentGroup, ...systemGroups.filter(group => group !== currentGroup)];
        }
      });
  }

  ngOnDestroy(): void {
    if (this.currentGroupSubscription) {
      this.currentGroupSubscription.unsubscribe();
    }
  }

  getGroupName(group: GroupType): string {
    return GROUP_LABEL_MAP[group];
  }

  onOpenPicker(): void {
    this.collapsed = !this.collapsed;
  }

  onUpdateGroup(group: GroupType): void {
    this.store.dispatch(new UpdateGroup(group));
  }
}
