import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {Navigate} from '@ngxs/router-plugin';
import {Observable, Subject, throwError} from 'rxjs';
import moment from 'moment';

import {DialogService} from '@core/services/local/dialog.service';
import {AuthService} from '@core/services/api/auth.service';
import {AuthState} from '@core/states/auth/auth.state';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {RemoveToken, UpdateToken} from '@core/states/auth/actions';
import {HTTP_SKIP_AUTH_TOKEN_EXPIRATION_CHECK, HTTP_SKIP_ERROR_HANDLING} from '@core/constants/constants';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  refreshTokenInProgress = false;

  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(
    private store: Store,
    private router: Router,
    private authService: AuthService,
    private dialogService: DialogService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);

    if (isAuthenticated) {
      request = this.addAuthHeader(request);
      const skipCheckingAuthTokenExpiration = request.params.has(HTTP_SKIP_AUTH_TOKEN_EXPIRATION_CHECK);

      if (skipCheckingAuthTokenExpiration) {
        request = request.clone({
          params: request.params.delete(HTTP_SKIP_AUTH_TOKEN_EXPIRATION_CHECK)
        })
      }

      if (!skipCheckingAuthTokenExpiration && this.isTokenExpired()) {
        return this.launchTokenRefresh(request, next);
      }
    }

    return next.handle(request)
      .pipe(
        catchError(error => this.handleResponseError(error, request, next))
      );
  }

  addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.store.selectSnapshot(AuthState.token);

    return request.clone({
      setHeaders: {Authorization: `Bearer ${token.access}`}
    });
  }

  isTokenExpired(): boolean {
    const {accessTokenExpireDate} = this.store.selectSnapshot(AuthState);
    const leftSeconds = moment().diff(accessTokenExpireDate, 'seconds');

    return leftSeconds > -30;
  }

  launchTokenRefresh(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return this.refreshToken()
      .pipe(
        switchMap(() => {
          request = this.addAuthHeader(request);
          return next.handle(request);
        }),
        catchError(error => {
          if (error.status === 401) {
            this.store.dispatch([RemoveToken, new Navigate(['/auth'])]);
            return throwError(error);
          }

          return this.handleResponseError(error, request, next);
        }));
  }

  handleResponseError(error: any, request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (request.params.has(HTTP_SKIP_ERROR_HANDLING)) {
      return throwError(error);
    }

    let message = null;
    switch (error.status) {
      case 500:
        message = 'Ошибка сервера';
        break;
      case 404:
        message = 'Не найдено';
        break;
      default:
        message = this.extractHttpErrorMessage(error.error);
    }

    this.dialogService.error({message});
    return throwError(error);
  }

  refreshToken(): Observable<any> {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    }

    this.refreshTokenInProgress = true;
    const token = this.store.selectSnapshot(AuthState.token);

    return this.authService.refreshToken(token.refresh)
      .pipe(
        map(newToken => {
          this.store.dispatch(new UpdateToken(newToken));
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next();
          return newToken;
        }),
        catchError(error => {
          this.refreshTokenInProgress = false;
          return throwError(error);
        })
      );
  }

  extractHttpErrorMessage(data: any): any {
    if (!data || typeof data === 'string') {
      return data;
    }

    if (Array.isArray(data)) {
      return this.extractHttpErrorMessage(data[0]);
    }

    const objKeys = Object.keys(data);
    return this.extractHttpErrorMessage(data[objKeys[0]]);
  }
}
