import {Component} from '@angular/core';
import {Store} from '@ngxs/store';

import {NavigateHome} from '@core/states/auth/actions';


@Component({
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss']
})
export class NavigatorComponent {

  constructor(
    private store: Store,
  ) {
    this.store.dispatch(NavigateHome);
  }
}
