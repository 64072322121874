import {Component} from '@angular/core';
import {ToastService} from '@core/services/local/toast.service';

@Component({
  selector: 'core-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent {

  constructor(
    public toastService: ToastService
  ) {
    setInterval(() => {
      [...this.toastService.toasts].forEach(toast => {
        if (toast.date < new Date()) {
          this.toastService.remove(toast.id);
        }
      });
    }, 1000);
  }
}
