<div class="modal-header" *ngIf="options.title">{{options.title}}</div>

<div class="modal-body">{{options.message}}</div>

<div class="modal-footer justify-content-end">

  <button
    class="btn btn-outline-secondary mr-2"
    *ngIf="options.cancelText"
    (click)="onClose()"
  >
    {{options.cancelText}}
  </button>

  <button class="btn btn-primary" (click)="onConfirm()">{{options.confirmText}}</button>

</div>
