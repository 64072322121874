<div class="wrapper" *ngIf="currentGroup$ | async">

  <div class="fixed-wrapper">

    <nav>

      <div class="d-flex align-items-center">

        <img class="logo" src="./assets/img/logo.svg" (click)="onNavigateHome()"/>

        <div class="nav-items">
          <div class="nav-item" (click)="onNavigateHome()">JooAdviser</div>
        </div>

      </div>

      <div class="nav-items">

        <div class="nav-item">
          <core-header-group-toggle></core-header-group-toggle>
        </div>

        <div class="nav-item">
          <core-header-lang-toggle></core-header-lang-toggle>
        </div>

        <div class="nav-item" (click)="onLogout()">Выйти</div>

      </div>

    </nav>

    <core-breadcrumbs></core-breadcrumbs>

  </div>

</div>
