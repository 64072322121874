import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {NavigatorComponent} from '@core/components/navigator/navigator.component';
import {RedirectGuard} from '@core/guards/redirect.guard';
import {AuthGuard} from '@core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: NavigatorComponent,
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./features/auth/auth.module').then(module => module.AuthModule),
    canActivate: [RedirectGuard],
  },
  {
    path: 'consult-front',
    loadChildren: () => import('./features/consult-front/consult-front.module').then(module => module.ConsultFrontModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'consult-cashier',
    loadChildren: () => import('./features/consult-cashier/consult-cashier.module').then(module => module.ConsultCashierModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'consult-middle',
    loadChildren: () => import('./features/consult-middle/consult-middle.module').then(module => module.ConsultMiddleModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'consult-back',
    loadChildren: () => import('./features/consult-back/consult-back.module').then(module => module.ConsultBackModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
