import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private _toasts: any[] = [];

  get toasts(): any[] {
    return this._toasts.sort((toast_1, toast_2) => toast_2.date - toast_1.date);
  }

  show(message: string): void {
    const date = new Date();
    date.setSeconds(date.getSeconds() + 2);
    this._toasts.push({id: Math.random().toString(), message, date});
  }

  remove(id: number): void {
    this._toasts = this._toasts.filter(toast => toast.id !== id);
  }
}
