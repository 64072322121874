import {Component, Input, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

import {LangModel, LANGS} from '@core/models/local/lang.model';
import {LangService} from '@core/services/local/lang.service';
import {LangType} from '@core/types/api/lang.type';

@Component({
  selector: 'core-header-lang-toggle',
  templateUrl: './header-lang-toggle.component.html',
  styleUrls: ['./header-lang-toggle.component.scss'],
})
export class HeaderLangToggleComponent implements OnDestroy {

  langs: any[];

  currLang: LangModel;

  langSubscription: Subscription;

  collapsed = true;

  constructor(
    private langService: LangService
  ) {
    this.langSubscription = this.langService.lang$
      .subscribe(lang => {
        this.currLang = LANGS.find(l => l.id === lang);
        this.langs = [this.currLang, ...LANGS.filter(l => l.id !== lang)];
      });
  }

  ngOnDestroy(): void {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }

  onOpenPicker(): void {
    this.collapsed = !this.collapsed;
  }

  onUpdateLang(lang: LangType): void {
    this.langService.updateLang(lang);
  }
}
