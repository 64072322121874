import {Component} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';

import {Logout, NavigateHome} from '@core/states/auth/actions';
import {AuthState} from '@core/states/auth/auth.state';
import {GroupType} from '@core/types/api/group.type';

@Component({
  selector: 'core-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @Select(AuthState.currentGroup) currentGroup$: Observable<GroupType>;

  constructor(
    private store: Store,
  ) {
  }

  onNavigateHome(): void {
    this.store.dispatch(NavigateHome);
  }

  onLogout(): void {
    this.store.dispatch(Logout);
  }
}
